ody, html {
    height: 100%;
    margin: 0;
}

.myloader {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
    height: 100vh !important;
    width: 100% !important;
	margin: auto;
}

.yellow {
	background:yellow;
}

.mynavtext {
    color: var(--primary-color);
}

.cover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 0;
	background: #b9b7ab;
	overflow:hidden;
}

.globalsignout {
	background-color: yellow;
	color: var(--primary-color);
	font-size: 15px;
	font-style: bold;
}

.bottomspace {
	padding-bottom:130px;
}

.signout {
	width: 100px;
	padding-right: 150px;
}

.roomicon {
	padding-top:8px;
}

.categoryicon {
	padding-top:8px;
}

.App {
  text-align: center;
}

.splide__slider>.splide__arrows .splide__arrow--prev {
    left: -2.5em
}

.splide__slider>.splide__arrows .splide__arrow--next {
    right: -2.5em
}

.splide__pagination {
    bottom: 1em;
}

.channel {
	border: 1px solid var(--primary-color);
	border-radius: 5px;
	background-color: #dbd9c8;
}

.channelbox{
	border: 1px solid var(--my-text-color);
	border-radius: 10px;
	color: var(--my-text-color);
}

.channeltext{
	border: 1px solid var(--my-text-color);
	border-radius: 5px;
	color: var(--my-text-color);
}

.channelinput {
	padding-right: 15px;
	text-align: end;
}

.channelcat {
    color: var(--primary-color);
	background-color: #dbd9c8;
	border-radius: 10px;
}

.channelcattext {
	font-size: 14px;
}

.categorycard {
    border-radius: 10px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-left: 15px;
	margin-right: 15px;
	border: none;
	cursor: pointer;
    color: var(--primary-color);
	background-color: var(--my-text-color);
}

.roomcard {
    border-radius: 10px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-left: 15px;
	margin-right: 15px;
	border: none;
	cursor: pointer;
	color: var(--my-text-color);
    background-color: var(--primary-color);
}

.mainbutton {
    background-color: var(--primary-color);
	color: var(--my-text-color);
	border: 2px solid var(--my-text-color);
	text-align: center;
	border-radius: 40px;
	font-size: 16px;
	padding-left: 12px;
	padding-right: 12px;
}

.mainbuttonprogress {
    background-color: yellow;
	color: black;
	border: 2px solid var(--my-text-color);
	text-align: center;
	border-radius: 40px;
	font-size: 16px;
	padding-left: 12px;
	padding-right: 12px;
}

.buttonblank {
    background-color: transparent;
	color: var(--my-text-color);
	border: none;
}

.buttonprogress {
    background-color: yellow;
	color: black;
	border: none;
}

.textbutton{
    background-color: transparent;
	color: var(--my-text-color);
	border: 2px solid var(--my-text-color);
	border-radius: 40px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 4px;
	padding-bottom: 4px;
}

.textbuttonprogress{
    background-color: yellow;
	color: black;
	border: 2px solid var(--my-text-color);
	border-radius: 40px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 4px;
	padding-bottom: 4px;
}

.refreshbutton {
    background-color: var(--primary-color);
	color: var(--my-text-color);
	border: 2px solid grey;
	text-align: center;
	border-radius: 40px;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: -15px;
}

.refreshbuttonprogress {
    background-color: var(--primary-color);
	color: var(--my-text-color);
	border: 2px solid yellow;
	text-align: center;
	border-radius: 40px;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: -15px;
}

.remoteheader {
	background-color: var(--primary-invert-color);
    color: var(--primary-color);
	border-radius: 5px;
	text-align: center;
}

.okbuttonouter {
    position: relative;
    width: 170px;
    height: 170px;
	background-color: var(--primary-invert-color);
    margin: 5px;
    float: center;
	border-radius: 200px;
}

.okbuttoninner {
    position: absolute;
    top: 17%;
    left: 17%;
    display: block;
	border-radius: 50%;
    width: 66%;
    height: 66%;
    background-color: var(--primary-color);
}

.okbutton {
    position: absolute;
    top: 40%;
    left: 30%;
    width: 40%;
    height: 20%;
}

.upbutton {
    position: absolute;
    top: 0%;
    left: 42%;
    width: 16%;
    height: 14%;
}

.downbutton {
    position: absolute;
    bottom: 0%;
    left: 42%;
    width: 16%;
    height: 18%;
}

.leftbutton {
    position: absolute;
    top: 40%;
    left: 0%;
    width: 16%;
    height: 18%;
}

.rightbutton {
    position: absolute;
    top: 40%;
    right: 0%;
    width: 16%;
    height: 18%;
}

.card-deck {
    border-radius: 10px;
	margin-top: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.container{
	overflow-y: scroll;
	height:100vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #46ec3b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(28px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switchcard {
    border-radius: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin: 10px;
	border: none;
  	text-align: center;
	display: inline-block;
	color: var(--my-text-color);
	width: 300px;
    background-color: var(--primary-color);
}

.sensorcard{
    border-radius: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin: 10px;
	border: none;
  	text-align: center;
	display: inline-block;
	color: var(--my-text-color);
	width: 250px;
    background-color: var(--primary-color);
}
.scenecard {
    border-radius: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin: 10px;
	border: none;
  	text-align: center;
	display: inline-block;
	color: var(--my-text-color);
	width: 300px;
    background-color: var(--primary-color);
}

.messagebox{
    border-radius: 10px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-left: 15px;
	margin-right: 15px;
	border: none;
  	text-align: center;
	display: inline-block;
	cursor: pointer;
	color: var(--my-text-color);
    background-color: var(--primary-color);
	width:300px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

amplify-authenticator.amplify-sign-in {
  background: red;
  padding: 5px;
}

:root {
  --amplify-primary-color: #2d2111;
  --amplify-primary-tint: #2d2111;
  --amplify-secondary-tint: #2d2111;
  --amplify-primary-shade: #2d2111;
  --amplify-white: #ffffffcc;
  --primary-color: #2d2111;
  --primary-invert-color: #efefef;
  --my-text-color: #efefef;
}
